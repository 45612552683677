import axios from 'axios';
import authHeader from './auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const getProvince = async () => {
	return axios.get(`${API_URL_DEFAULT}customers/province`, {
		headers: await authHeader(),
	});
};

const getCity = async () => {
	return axios.get(`${API_URL_DEFAULT}customers/city`, {
		headers: await authHeader(),
	});
};

const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}customers`, payload, {
		headers: await authHeader(),
	});
};

export default { getProvince, getCity, create };
