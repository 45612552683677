import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import PageLayoutHeader from '../pages/common/Headers/PageLayoutHeader';
import Alert, { AlertHeading } from '../components/bootstrap/Alert';
import Card, { CardBody } from '../components/bootstrap/Card';
import DashboardModule from '../modules/DashboardModule';
import { getRequester, getaActiveRoles } from '../helpers/helpers';
import showNotification from '../components/extras/showNotification';

const getAccToken = JSON.parse(localStorage.getItem('accessToken', {}));
const decodeToken = getAccToken ? jwt_decode(getAccToken.accessToken) : null;

const Dashboard = () => {
	const [dataReport, setDataReport] = useState([]);
	const { username } = getRequester();
	const { default_segment_code } = getaActiveRoles();

	// handle first time render
	useEffect(() => {
		fetchDashboard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// reload data table
	const fetchDashboard = async () => {
		const query = `username=${username}&segment_code=${default_segment_code}`;
		return DashboardModule.read(query)
			.then((response) => {
				setDataReport(response.dashboard);
			})
			.catch((err) => {
				showNotification('Information', err, 'danger');
			});
	};

	return (
		<PageWrapper title='Dashboard'>
			<PageLayoutHeader />
			<Page container='fluid'>
				<div className='row'>
					<div className='col-12'>
						<Alert
							icon='Verified'
							isLight
							color='success'
							rounded={2}
							borderWidth={0}
							className='shadow-3d-primary'>
							<AlertHeading tag='h2' className='h4'>
								Welcome{' '}
								{decodeToken &&
									decodeToken.details.hris_org_tree.current_person.person_name}
							</AlertHeading>
						</Alert>
					</div>
				</div>
				{dataReport &&
					dataReport.length > 0 &&
					dataReport.map((x) => (
						<Card>
							<CardBody style={{ height: '100%' }}>
								<div
									className='col-12'
									style={{
										height: '600px',
										width: '100%',
										overflowY: 'auto',
										overflowX: 'auto',
									}}>
									<iframe src={x} title='1' height='100%' width='100%' />
								</div>
							</CardBody>
						</Card>
					))}
			</Page>
		</PageWrapper>
	);
};

export default Dashboard;
