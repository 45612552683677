import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import COLORS from '../common/data/enumColors';
import { debounce } from '../helpers/helpers';

const CustomSelect = ({
	options,
	onChange,
	value,
	isMulti,
	isSearchable,
	darkTheme,
	isValid,
	isDisabled,
	placeholder,
	invalidFeedback,
	isClearable,
	setRef,
	isAsync,
	fetchOptions,
}) => {
	const theme_select = (theme) => ({
		...theme,
		colors: {
			...theme.colors,
			primary25: darkTheme ? '#606AEB' : '#DEEBFF',
			primary: darkTheme ? '#3942BF' : '#3942BF',
		},
	});
	const border_color_select = (valid, disable) => {
		if (disable) return darkTheme ? '#424242' : '#DBDBDB';
		if (!valid) return '#F35421';
		return darkTheme ? '#343A40' : '#CDCDCD';
	};
	const menu_color_select = (disable) => {
		if (disable) return darkTheme ? '#343A40' : '#E9ECEF';
		return darkTheme ? '#242731' : 'white';
	};
	const styles_select = {
		control: (styles) => ({
			...styles,
			color: !darkTheme ? '#242731' : 'white',
			backgroundColor: menu_color_select(isDisabled),
			borderColor: border_color_select(isValid, isDisabled),
			'&:hover': {
				borderColor: darkTheme ? COLORS.INFO.code : '#818181',
			},
		}),
		menu: (styles) => ({
			...styles,
			color: 'white',
			backgroundColor: '#242731',
		}),
		menuList: (styles) => ({
			...styles,
			color: !darkTheme ? '#242731' : 'white',
			backgroundColor: darkTheme ? '#242731' : 'white',
		}),
		singleValue: (styles) => {
			return {
				...styles,
				color: !darkTheme ? '#242731' : 'white',
				backgroundColor: darkTheme ? '#242731' : 'white',
			};
		},
		multiValue: (styles) => {
			return {
				...styles,
				backgroundColor: '#CCFFFF',
			};
		},
		multiValueLabel: (styles) => ({
			...styles,
			color: '#242731',
		}),
		multiValueRemove: (styles) => ({
			...styles,
			color: darkTheme ? '#242731' : 'white',
			backgroundColor: '#F76A76',
			':hover': {
				color: !darkTheme ? '#242731' : 'white',
				backgroundColor: darkTheme ? '#ED4A57' : '#FF4A4A',
			},
		}),
		menuPortal: (base) => ({ ...base, zIndex: 9999 }),
	};

	const loadOptions = debounce((search) => {
		const query = { search };
		return fetchOptions(query);
	});

	return (
		<div>
			{isAsync ? (
				<AsyncSelect
					ref={setRef}
					cacheOptions
					defaultOptions={options.length === 0 ? true : options}
					loadOptions={loadOptions}
					onChange={onChange}
					isDisabled={isDisabled}
					placeholder={placeholder}
					isSearchable={isSearchable}
					value={value}
					isMulti={isMulti}
					isClearable={isClearable}
					styles={styles_select}
					menuPortalTarget={document.body}
					theme={theme_select}
				/>
			) : (
				<Select
					ref={setRef}
					options={options}
					onChange={onChange}
					isDisabled={isDisabled}
					placeholder={placeholder}
					isSearchable={isSearchable}
					value={value}
					isMulti={isMulti}
					isClearable={isClearable}
					styles={styles_select}
					menuPortalTarget={document.body}
					theme={theme_select}
				/>
			)}
			{!isValid && (
				<div style={{ color: COLORS.DANGER.code, fontSize: '0.875em' }}>
					{invalidFeedback}
				</div>
			)}
		</div>
	);
};

CustomSelect.propTypes = {
	options: PropTypes.oneOfType([PropTypes.instanceOf(Object)]),
	onChange: PropTypes.func,
	value: PropTypes.instanceOf(Object),
	isMulti: PropTypes.bool,
	isSearchable: PropTypes.bool,
	darkTheme: PropTypes.bool,
	isValid: PropTypes.bool,
	isDisabled: PropTypes.bool,
	placeholder: PropTypes.string,
	invalidFeedback: PropTypes.string,
	isAsync: PropTypes.bool,
	isClearable: PropTypes.bool,
	setRef: PropTypes.oneOfType([PropTypes.instanceOf(Object)]),
	fetchOptions: PropTypes.func,
};
CustomSelect.defaultProps = {
	options: [],
	onChange: undefined,
	value: {},
	isMulti: false,
	isSearchable: false,
	darkTheme: false,
	isValid: true,
	isDisabled: false,
	placeholder: 'Select...',
	invalidFeedback: 'Required',
	isClearable: false,
	isAsync: false,
	setRef: null,
	fetchOptions: null,
};

export default CustomSelect;
