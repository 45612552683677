import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import Button from '../components/bootstrap/Button';
import Alert from '../components/bootstrap/Alert';
import Input from '../components/bootstrap/forms/Input';
import FormGroup from '../components/bootstrap/forms/FormGroup';
import Textarea from '../components/bootstrap/forms/Textarea';
import CustomSelect from '../components/CustomSelect';
import DataCustomerModule from '../modules/DataCustomerModule';
import showNotification from '../components/extras/showNotification';
import '../styles/views/data_customer.css';
import Modal, { ModalBody } from '../components/bootstrap/Modal';

const DataCustomer = () => {
	const [title] = useState({ title: 'Data Customer' });
	const [provinceSelected, setProvinceSelected] = useState(null);
	const [province, setProvince] = useState([]);
	const [citySelected, setCitySelected] = useState(null);
	const [cityTemp, setCityTemp] = useState([]);
	const [city, setCity] = useState([]);
	const [responseData, setResponseData] = useState(null);
	const [longitude, setLongitude] = useState(null);
	const [latitude, setLatitude] = useState(null);
	const [isTermChecked, setIsTermChecked] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: '',
			age: '',
			email: '',
			phone: '',
			address: '',
		},
		onSubmit: (values, { setSubmitting, setStatus, setErrors }) => {
			if (!values.name) {
				showNotification(
					'Peringatan!',
					'Input nama masih kosong, silahkan diisi terlebih dahulu',
					'danger',
				);
			}
			if (values.age.toString().length > 3) {
				showNotification(
					'Peringatan!',
					'Input usia tidak boleh lebih dari 3 digit',
					'danger',
				);
			}
			if (!values.age) {
				showNotification(
					'Peringatan!',
					'Input usia masih kosong, silahkan diisi terlebih dahulu',
					'danger',
				);
			}
			if (values.email && values.email.includes('+')) {
				showNotification('Peringatan!', 'Input email tidak valid', 'danger');
			}
			if (!values.email) {
				showNotification(
					'Peringatan!',
					'Input email masih kosong, silahkan diisi terlebih dahulu',
					'danger',
				);
			}
			if (values.phone.toString().length < 10) {
				showNotification(
					'Peringatan!',
					'Input nomor WA tidak boleh kurang dari 11 digit',
					'danger',
				);
			}
			if (values.phone.toString().length > 12) {
				showNotification(
					'Peringatan!',
					'Input nomor WA tidak boleh lebih dari 13 digit',
					'danger',
				);
			}
			if (!values.phone) {
				showNotification(
					'Peringatan!',
					'Input nomor WA masih kosong, silahkan diisi terlebih dahulu',
					'danger',
				);
			}
			if (citySelected === null) {
				showNotification(
					'Peringatan!',
					'Input kota kosong, silahkan diisi terlebih dahulu',
					'danger',
				);
			}
			if (!values.address) {
				showNotification(
					'Peringatan!',
					'Input alamat kosong, silahkan diisi terlebih dahulu',
					'danger',
				);
			}
			if (provinceSelected === null) {
				showNotification(
					'Peringatan!',
					'Input provinsi kosong, silahkan diisi terlebih dahulu',
					'danger',
				);
			}
			values.province_code = provinceSelected.value;
			values.province_name = provinceSelected.label;
			values.regional_code = provinceSelected.regional_code;
			values.city_code = citySelected.value;
			values.city_name = citySelected.label;
			values.longitude = longitude;
			values.latitude = latitude;
			try {
				handleSubmit(values);
				setStatus({ success: true });
			} catch (error) {
				setStatus({ success: false });
				setSubmitting(false);
				setErrors({ submit: error.message });
			}
		},
	});

	const handleSubmit = (values) => {
		if (
			values.name &&
			values.age &&
			values.email &&
			values.phone &&
			values.regional_code &&
			values.city_code &&
			values.address &&
			!values.email.includes('+') &&
			values.age.toString().length < 4 &&
			values.phone.toString().length < 14 &&
			values.phone.toString().length > 9
		) {
			return Swal.fire({
				title: 'Simpan data?',
				text: 'Periksa kembali data anda !',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Ya',
				cancelButtonText: 'Batal',
			}).then((result) => {
				if (result.value) {
					setLoadingSubmit(true);
					DataCustomerModule.create(values)
						.then((res) => {
							setLoadingSubmit(false);
							setResponseData(res.data);
							if (res.status === 200) {
								showNotification('Berhasil!', res.message, 'success');
							} else {
								showNotification('Gagal!', res.message, 'danger');
							}
						})
						.catch((error) => {
							setLoadingSubmit(false);
							showNotification('Warning!', error, 'danger');
						});
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					setLoadingSubmit(false);
					Swal.fire('Batal', 'Data anda belum tersimpan! :)', 'error');
				}
			});
		}
		return values;
	};

	const fetchProvince = async () => {
		try {
			await DataCustomerModule.getProvince().then((res) => {
				setProvince(res);
			});
		} catch (error) {
			throw error(error);
		}
	};
	const fetchCity = async () => {
		try {
			await DataCustomerModule.getCity().then((res) => {
				setCityTemp(res);
				setCity(res);
			});
		} catch (error) {
			throw error(error);
		}
	};
	useEffect(() => {
		fetchProvince();
		fetchCity();
		navigator.geolocation.getCurrentPosition((position) => {
			setLatitude(position.coords.latitude.toString());
			setLongitude(position.coords.longitude.toString());
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PageWrapper title={title.title} className='p-0'>
			<div className='content-wrapper'>
				<Page>
					<div className='row'>
						<div className='col mb-3 mt-n2'>
							<div className='form-container'>
								<form onSubmit={formik.handleSubmit}>
									<div>
										<img
											src='/images/webbanner.jpg'
											alt='Banner'
											style={{
												width: '100%',
												marginBottom: '1.5rem',
											}}
										/>
										{!responseData && (
											<div style={{ padding: '16px 16px 16px 16px' }}>
												<span style={{ fontSize: '2rem' }}>
													Form Customer
												</span>
												<br />
												<label
													className='form-check-label'
													htmlFor='flexCheckChecked'>
													<br />
													Mau RichCreme Non Dairy Creamer GRATIS?
												</label>
												<p>
													Isi data di bawah ini dan tukarkan kuponnya di
													Indomaret!
												</p>
												<hr style={{ marginBottom: '2rem' }} />
												<div className='d-flex flex-wrap justify-content-between'>
													<FormGroup
														id='name'
														label='Nama Lengkap'
														className='col-md-12 mb-3 field-input'>
														<Input
															className='bg-white shadow'
															style={{ height: '40px' }}
															type='text'
															name='name'
															onChange={formik.handleChange}
															value={formik.values.name}
															onBlur={formik.handleBlur}
															isValid={formik.isValid}
															autoComplete='off'
														/>
													</FormGroup>
													<FormGroup
														id='age'
														label='Usia'
														className='col-md-12 mb-3 field-input'>
														<Input
															className='bg-white shadow'
															style={{ height: '40px' }}
															type='number'
															name='age'
															min='0'
															onChange={formik.handleChange}
															value={formik.values.age}
															onBlur={formik.handleBlur}
															isValid={formik.isValid}
															autoComplete='off'
														/>
													</FormGroup>
													<FormGroup
														id='email'
														label='Email'
														className='col-md-12 mb-3 field-input'>
														<Input
															className='bg-white shadow'
															style={{ height: '40px' }}
															type='email'
															name='email'
															onChange={formik.handleChange}
															value={formik.values.email}
															onBlur={formik.handleBlur}
															isValid={formik.isValid}
															autoComplete='off'
														/>
													</FormGroup>
													<FormGroup
														id='phone'
														label='Nomor WA'
														className='col-md-12 mb-3 field-input'>
														<Input
															className='bg-white shadow'
															style={{ height: '40px' }}
															type='number'
															name='phone'
															min='0'
															onChange={formik.handleChange}
															value={formik.values.phone}
															onBlur={formik.handleBlur}
															isValid={formik.isValid}
															autoComplete='off'
														/>
													</FormGroup>
													<FormGroup
														id='province'
														label='Provinsi'
														className='col-md-12 mb-3 field-input'>
														<CustomSelect
															className='bg-white shadow rounded'
															placeholder='Provinsi'
															onChange={(e) => {
																setCity(cityTemp);
																setCity((state) =>
																	state.filter(
																		(value) =>
																			value.province_code !=
																				undefined &&
																			value.province_code.toLowerCase() ==
																				e.value.toLowerCase() &&
																			value.province_name.toLowerCase() ==
																				e.label.toLowerCase(),
																	),
																);
																setProvinceSelected(e);
															}}
															value={provinceSelected}
															isSearchable
															options={province}
														/>
													</FormGroup>
													<FormGroup
														id='city'
														label='Kota'
														className='col-md-12 mb-3 field-input'>
														<CustomSelect
															className='bg-white shadow rounded'
															placeholder='City'
															onChange={(e) => setCitySelected(e)}
															value={citySelected}
															isSearchable
															options={city}
															isDisabled={provinceSelected == null}
														/>
													</FormGroup>
													<FormGroup
														id='address'
														label='Alamat'
														className='col-md-12 mb-3 field-input address-input'>
														<Textarea
															className='bg-white shadow'
															name='address'
															onChange={formik.handleChange}
															value={formik.values.address}
															onBlur={formik.handleBlur}
															isValid={formik.isValid}
															autoComplete='off'
														/>
													</FormGroup>
												</div>
											</div>
										)}
										{responseData && (
											<div className='p-4 mb-2'>
												<Alert
													style={{
														backgroundColor: 'lightgreen',
														border: 'none',
														margin: 'auto',
													}}
													isDismissible
													className='text-dark'>
													Berhasil Menyimpan Data
												</Alert>
												<div className='d-flex justify-content-center mt-5'>
													<div>
														<h1 className='mb-4'>
															Hi {responseData?.customer_name},
														</h1>
														{responseData?.customer_name && (
															<>
																<p>
																	Tringgggg…💫💫 <br />
																	Balik lagi nih sama Richmin!
																	<br />
																	Iya-iya tau kok kalo kamu udah
																	ga sabar buat nambahin RichCreme
																	Non Dairy Creamer ke minuman
																	favoritmu🧋☕biar makin
																	#CreamyToTheMAX🤩🤩
																</p>
																<p>
																	Kode voucher akan cusss dikirim
																	melalui email kamu yah! (Aseeekk
																	bisa ngupi ngeteh bareng temen &
																	si doi) <br />
																	Cusss buruan cek email yahh!
																	Jangan lupa juga buat follow
																	social media @richcreme.id buat
																	dapetin promo seru
																	lainnya🏃🏼🏃🏼🏃🏼🏃🏼
																</p>
															</>
														)}
													</div>
												</div>
											</div>
										)}
									</div>
									{!responseData && (
										<div style={{ padding: '16px 16px 16px 16px' }}>
											<div className='form-check mb-4'>
												<input
													className='form-check-input'
													type='checkbox'
													value=''
													id='flexCheckChecked'
													onChange={() =>
														setIsTermChecked(!isTermChecked)
													}
													checked={isTermChecked}
												/>
												<label
													className='form-check-label'
													htmlFor='flexCheckChecked'>
													Dengan mengisi form ini saya menyetujui{' '}
													<a href='privacy-policy' target='_blank'>
														Syarat dan Ketentuan
													</a>
												</label>
											</div>
											<Button
												color='primary'
												type='submit'
												disabled={!isTermChecked}
												className='w-100 py-2 fs-5'>
												Submit
											</Button>
										</div>
									)}
								</form>
							</div>
						</div>
					</div>
				</Page>
			</div>
			<Modal
				isOpen={loadingSubmit}
				size='sm'
				isCentered
				setIsOpen={() => {}}
				isStaticBackdrop>
				<ModalBody
					style={{ backgroundColor: '#6c5dd3', color: 'white' }}
					className='text-center'>
					<button className='btn btn-primary' type='button' disabled>
						<span
							className='spinner-grow spinner-grow-sm'
							role='status'
							aria-hidden='true'
						/>
						&nbsp;
						<span
							className='spinner-grow spinner-grow-sm'
							role='status'
							aria-hidden='true'
						/>
						&nbsp;
						<span
							className='spinner-grow spinner-grow-sm'
							role='status'
							aria-hidden='true'
						/>
						&nbsp;
						<span className='sr-only'>Loading...</span>
					</button>
				</ModalBody>
			</Modal>
		</PageWrapper>
	);
};

export default DataCustomer;
