import DataCustomerService from '../services/data_customer.service';

const getProvince = () => {
	return DataCustomerService.getProvince().then(
		(response) => Promise.resolve(response.data, response.data.message),
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const getCity = () => {
	return DataCustomerService.getCity().then(
		(response) => Promise.resolve(response.data, response.data.message),
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const create = (values) => {
	return DataCustomerService.create(values).then(
		(response) => Promise.resolve(response.data, response.data.message),
		(error) => {
			const message =
				(error.message && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default { getProvince, getCity, create };
