import axios from 'axios';
import authHeader from './auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const read = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}dashboard/?${query_string}`, {
		headers: await authHeader(),
	});
};

export default { read };
