const LANG = {
	EN: {
		text: 'English',
		lng: 'en-US',
		icon: 'CustomUsa',
	},
	// ID: {
	// 	text: 'Indonesia',
	// 	lng: 'id-ID',
	// 	icon: 'CustomIndonesia',
	// },
};

export const getLangWithKey = (key) => {
	return LANG[Object.keys(LANG).filter((f) => LANG[f].lng === key)];
};

export default LANG;
